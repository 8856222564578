.map-container {
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

button.map-overlay-open-profile {
  z-index: 100;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
  background-color: #fff2bb;
  background-size: 70%;
  background-position: center;
  border: 2px solid white;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 4px #0006;
}

button.map-overlay-toggle-favorites {
  z-index: 100;
  position: absolute;
  right: 15px;
  top: 85px;
  width: 55px;
  height: 55px;
  background: #999;
  border: 2px solid white;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 4px #0006;
}

button.map-overlay-toggle-favorites > svg {
  color: #ccc;
}

button.map-overlay-toggle-favorites.on {
  background: #ff6600;
}

@keyframes spinFavorites {
  from {
    transform: rotateY(0deg);
    color: #fff;
  }

  10% {
    color: #ffd700;
  }

  50% {
    transform: rotateY(85deg);
    color: #68665c;
  }

  90% {
    color: #ffd700;
  }

  to {
    transform: rotateY(0deg);
    color: #fff;
  }
}

button.map-overlay-toggle-favorites.on > svg {
  color: #ffd700;
  animation-name: spinFavorites;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

button.map-overlay-toggle-blocked {
  z-index: 100;
  position: absolute;
  right: 15px;
  top: 155px;
  width: 55px;
  height: 55px;
  background: #099;
  border: 2px solid white;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 4px #0006;
  line-height: 5px;
}

button.map-overlay-toggle-blocked > svg {
  color: #ccc;
}

button.map-overlay-toggle-blocked.on {
  background: #c00;
}

button.map-overlay-toggle-blocked.on > svg {
  color: #fff;
}

button.map-overlay-toggle-invite {
  z-index: 100;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 55px;
  height: 55px;
  background: #069;
  border: 2px solid white;
  border-radius: 55px;
  cursor: pointer;
  box-shadow: 0 5px 4px #0006;
  line-height: 5px;
}

button.map-overlay-toggle-invite > svg {
  color: #ee0;
}

button.map-overlay-go-park {
  z-index: 100;
  position: absolute;
  border: none;
  border-radius: 13px;
  width: 256px;
  bottom: 50px;
  left: calc(50% - 128px);
  background-color: #24ad91;
  font-family: 'Amatic SC', Arial, Helvetica, sans-serif;
  font-size: 39px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 6px 8px #0006, 0 -1px 1px 2px inset #0003;
}

button.map-overlay-leave-park {
  z-index: 100;
  position: absolute;
  border: none;
  border-radius: 13px;
  width: 256px;
  bottom: 50px;
  left: calc(50% - 128px);
  background-color: #cf2f2f;
  color: #fff;
  font-family: 'Amatic SC', Arial, Helvetica, sans-serif;
  font-size: 39px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 6px 8px #0006, 0 -1px 1px 2px inset #0003;
}

.fas.fa-paw {
  z-index: 100;
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  background: #fff6;
  border-radius: 25px;
  padding: 3px;
  border: 2px solid black;
  pointer-events: none;
}
