/* @import url("./fonts/Amatic_SC/AmaticSC-Bold.ttf");
@import url("./fonts/Amatic_SC/AmaticSC-Regular.ttf");

@import url("./fonts/Roboto/Roboto-Regular.ttf");
@import url("./fonts/Roboto/Roboto-Bold.ttf");
@import url("./fonts/Roboto/Roboto-Italic.ttf");
@import url("./fonts/Roboto/Roboto-Light.ttf");

@import url("./fonts/Spectral/Spectral-Regular.ttf");
@import url("./fonts/Spectral/Spectral-Bold.ttf");
@import url("./fonts/Spectral/Spectral-Italic.ttf");
@import url("./fonts/Spectral/Spectral-Light.ttf"); */

@font-face {
  font-family: "Amatic SC";
  src: local("Amatic SC"),
    url(./fonts/Amatic_SC/AmaticSC-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Spectral";
  src: local("Spectral"), url(./fonts/Spectral/Spectral-Regular.ttf),
    format("truetype");
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.font-amatic {
  font-family: "Amatic SC", Arial, Helvetica, sans-serif;
}

.font-roboto {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

.font-spectral {
  font-family: "Spectral", Arial, Helvetica, sans-serif;
}
