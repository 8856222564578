.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-page h1 {
  color: black;
  font-family: 'Amatic SC', Arial, Helvetica, sans-serif;
  font-size: 75px;
  font-weight: 100;
  z-index: 2;
}

.home-page p {
  font-size: 22px;
  font-family: 'Spectral', Arial, Helvetica, sans-serif;
  margin-top: 115px;
  z-index: 3;
  text-align: center;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 60px;
}

.home-page .blue-box {
  background-color: #658795;
  position: absolute;
  width: 50.5%;
  height: 265px;
  right: 0;
  z-index: 1;
}

.home-page .green-box {
  background-color: #9ebf82;
  position: absolute;
  width: 50%;
  height: 200px;
  left: 0;
}

.home-page .container {
  max-width: 575px;
  margin: 0 auto;
  padding: 0;
}

.home-page .sign-in-name {
  display: flex;
  flex-direction: row;
}

.home-page .sign-in-button {
  display: flex;
  border-radius: 5px;
  width: 252px;
  height: 47px;
  border: 4px solid #4082ed;
}

.home-page .google-sign-in {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  width: 250px;
  border: 0;
  background-color: #4082ed;
  color: white;
  cursor: pointer;
}

.home-page #google-paw {
  width: 39px;
  height: 39px;
  border-radius: 10px;
  border: 1px solid white;
  padding: 4px;
}

@media screen and (min-width: 768px) {
  .container {
    max-width: auto;
  }
}
