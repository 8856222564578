.backdrop {
  position: fixed;
  inset: 0 0 0 0;
  background: #0003;
  z-index: 100;
  backdrop-filter: blur(4px);
}

.bark-popup {
  background-color: white;
  /* border: 2px solid black; */
  border-radius: 12px;
  position: absolute;
  width: 300px;
  top: calc(40% - 250px);
  left: calc(50% - 150px);
  padding: 20px;
  z-index: 100;
  box-shadow: 0 10px 8px #0006;
}

.bark-popup .header {
  overflow: hidden;
  margin: -20px -20px 0 -20px;
  border-radius: 12px 12px 0 0;
}

.bark-popup .form {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-left: 5px;
}

.bark-popup h1 {
  background-color: #f7c806;
  font-family: 'Amatic SC', Arial, Helvetica, sans-serif;
  font-size: 52px;
  font-weight: 100;
  margin: 0;
  text-align: center;
}

.bark-popup h4 {
  font-family: 'Spectral', Arial, Helvetica, sans-serif;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400;
}

.bark-popup .text {
  font-size: 18px;
  height: 35px;
  border-radius: 5px;
  padding: 0 6px;
  border: 1px solid #999;
  box-shadow: 0 1px 4px #0006 inset;
}

.bark-popup select {
  background-color: white;
  font-size: 18px;
  font-family: 'Spectral', Arial, Helvetica, sans-serif;
  border-radius: 5px;
  height: 35px;
  padding: 0 6px;
  border: 1px solid #999;
  box-shadow: 0 1px 4px #0006 inset;
  cursor: pointer;
}

.bark-popup .error-text {
  visibility: hidden;
  margin: 4px 0px 20px 0px;
  font-size: 12px;
  color: #c00;
  text-align: right;
}

.bark-popup .error-text.show {
  visibility: visible;
}

.bark-popup .error {
  border-color: #c00;
  background-color: #f003;
}

.bark-popup .buttons {
  display: flex;
  justify-content: space-between;
}

.bark-popup .submit {
  background-color: #c2d4b1;
  font-family: 'Spectral', Arial, Helvetica, sans-serif;
  font-size: 19px;
  border: 0;
  border-radius: 8px;
  align-items: center;
  width: 107px;
  height: 43px;
  cursor: pointer;
  box-shadow: 0 -1px 1px 2px inset #0003;
}

.bark-popup .submit:disabled {
  opacity: 0.75;
  border: none;
}

.bark-popup .cancel {
  background-color: #fff2bb;
  font-family: 'Spectral', Arial, Helvetica, sans-serif;
  font-size: 19px;
  border: 0;
  border-radius: 8px;
  width: 107px;
  height: 43px;
  cursor: pointer;
  box-shadow: 0 -1px 1px 2px inset #0003;
}

.bark-popup .image {
  width: 150px;
  height: 150px;
  border-radius: 90px;
  background-size: 67%;
  background-position: center;
  background-repeat: no-repeat;
  margin: 10px auto;
  position: relative;
  background-color: #fff2bb;
}

.bark-popup .image > img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 32px;
  background-color: white;
  border-radius: 26px;
  cursor: pointer;
}

.bark-popup .dog-icon-picker-select-button {
  top: 35px;
  left: 50px;
  position: absolute;
  width: 200px;
}

.bark-popup .dog-image-profile-selected {
  width: 90px;
  margin: auto;
  display: block;
}

div.sign-out-container {
  position: absolute;
  width: 100vw;
  bottom: 60px;
  text-align: center;
}

div.sign-out-container > button {
  background-color: #ef4949;
  font-family: 'Spectral', Arial, Helvetica, sans-serif;
  font-size: 16px;
  border: 0;
  border-radius: 8px;
  align-items: center;
  width: 120px;
  height: 30px;
  cursor: pointer;
  box-shadow: 0 -1px 1px 2px inset #0003;
}
